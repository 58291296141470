import { Route } from '@angular/router';
import { AuthGuard } from '@auth0/auth0-angular';
import { LicenseGuard } from '@bodyanalytics/front/admin/license/domain';

export const adminDashboardUiRoute: Route = {
  path: 'auth/admin-dashboard',
  loadComponent: () => import('./containers/admin-dashboard/admin-dashboard.component'),
  //TODO: Add LicenseGuard when license production error is fixed
  canActivate: [AuthGuard],
};
