<section id="manage-bodymeasurements-dashboard" class="dashboard bg-white">
  <div class="container">
    <div class="row text-white">
      <div class="col-md-12 bg-white p-2 mx-auto">
        <div class="w-100 pb-2">
          <div class="card border-0">
            <div class="manage-bodymeasurements-container">
              <div class="row">
                <div class="mx-auto">

                  <!-- Show the table if entities exist -->
                  <ng-container *ngIf="entities()!.length > 0; else noData">
                    <div class="card border-0">
                      <bodyanalytics-manage-bodymeasurements-table [entities]="entities()" />
                    </div>
                  </ng-container>

                  <!-- Template to display if no data -->
                  <ng-template #noData>
                    <bodyanalytics-default-empty-data-container></bodyanalytics-default-empty-data-container>
                  </ng-template>
                </div>

              </div>
            </div>

          </div>
        </div>
      </div>
    </div>
  </div>
</section>
