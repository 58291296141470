import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { NgIf } from '@angular/common';

import { ManageDiscountsStore } from '@bodyanalytics/front/admin/manage-discounts/domain';
import { ManageDiscountsTableComponent } from '@bodyanalytics/front/admin/manage-discounts/ui';
import { DefaultEmptyDataContainerComponent } from '@bodyanalytics/ui-core';

@Component({
  selector: 'bodyanalytics-discount-dashboard',
  templateUrl: './manage-discounts-dashboard.component.html',
  styleUrls: ['./manage-discounts-dashboard.component.scss'],
  imports: [ManageDiscountsTableComponent, NgIf, DefaultEmptyDataContainerComponent],
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DiscountDashboardComponent{
  entities = inject(ManageDiscountsStore).entities;

}

// extends BodyanalyticsBaseComponent {
//   public discountsData: DiscountI[] = [];
//   public $discounts!: Observable<DiscountI[]>;

//   @Input() item!: Observable<DiscountI[]>;
//   @Output() submitFormEvent = new EventEmitter<any>();

//   submitted = false;

//   isDiscountTableEnabled = true;

//   isDiscountIdSelected = '';

//   // discountsData: DiscountI[] = [] as DiscountI[];
//   validationform: FormGroup = {} as FormGroup;

//   constructor(
//     public formBuilder: FormBuilder,
//     private discountService: ManageDiscountService,
//     private route: ActivatedRoute,
//     private location: Location,
//   ) {
//     super();
//   }

//   public discountDetailsForm = new FormGroup({
//     name: new FormControl('', [Validators.required]),
//     date: new FormControl('', [Validators.required]),
//     startTime: new FormControl('', [Validators.required]),
//     endTime: new FormControl('', [Validators.required]),
//     signupInterval: new FormControl('', [Validators.required]),
//     allowSignupOnline: new FormControl('', [Validators.required]),
//     paymentRequired: new FormControl('', [Validators.required]),
//     privateEvent: new FormControl('', [Validators.required]),
//     assigned: new FormControl('', [Validators.required]),
//     note: new FormControl('', [Validators.required]),
//   });

//   ngOnInit(): void {
//     // this.breadCrumbItems = [{ label: 'Ecommerce' }, { label: 'Discounts', active: true }];
//     this._fetchData();
//   }

//   private _fetchData() {
//     //console.log('got you',  this.form.get('searchText')?.value);
//     this.subscriptions.barrel = this.discountService.getDiscounts().subscribe((results: DiscountI[]) => {
//       this.discountsData = results;
//     });

//     // this.subscriptions.barrel = this.item.subscribe(val => {

//     //   if(val){
//     //     this.discountsData = val as any
//     //   }

//     // });
//     // this.discountsData = discountsData;
//   }

//   /**
//    * Returns form
//    */
//   get form() {
//     return this.validationform.controls;
//   }

//   openModal(deleteContent: any, id: string) {
//     // const id = this.route.snapshot.paramMap.get('id') as string;
//     // this.modalService.open(deleteContent, { backdropClass: 'light-blue-backdrop', size: 'lg' });
//     this.isDiscountIdSelected = id;

//     // eslint-disable-next-line @typescript-eslint/no-empty-function
//   }

//   deleteDiscount() {
//     this.subscriptions.barrel = this.discountService
//       .deleteDiscount(this.isDiscountIdSelected)
//       .pipe(
//         tap(() => {
//           // this.modalService.dismissAll();
//           this.submitted = true;
//           location.reload();
//         }),
//         catchError((error: any) => {
//           // doc.file.hasError = true;
//           // doc.file.error = error.apiErrorMsg ? error.apiErrorMsg : this.genericErrorMessage;
//           // doc.file.uploading = false;
//           // doc.file.uploaded = false;
//           return of(error);
//         }),
//       )
//       .subscribe();
//   }
//   /**
//    * Modal Open
//    * @param content modal content
//    */

//   public createDiscount(signupDetails: any): void {
//     this.subscriptions.barrel = this.discountService
//       .addDiscount(signupDetails)
//       .pipe(
//         tap(() => {
//           // this.modalService.dismissAll();
//           this.submitted = true;
//         }),
//         catchError((error: any) => {
//           // doc.file.hasError = true;
//           // doc.file.error = error.apiErrorMsg ? error.apiErrorMsg : this.genericErrorMessage;
//           // doc.file.uploading = false;
//           // doc.file.uploaded = false;
//           return of(error);
//         }),
//       )
//       .subscribe();
//   }

//   /**
//    * save the contacts data
//    */
//   saveData() {
//     if (this.discountDetailsForm.valid) {
//       this.createDiscount(this.discountDetailsForm.value);
//     }
//   }
// }
