import { DatePipe, JsonPipe, NgIf } from '@angular/common';
import { AfterViewInit, ChangeDetectionStrategy, Component, effect, input, Input, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort, MatSortModule } from '@angular/material/sort';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { Router, RouterLink } from '@angular/router';
import { CustomerI, OrderI } from '@bodyanalytics/data-models-ui';
import { BodyanalyticsBaseComponent, MaterialModule, RouterUrlsEnum } from '@bodyanalytics/ui-core';
import { tap, catchError, of } from 'rxjs';
import { animations } from './manage-orders-table.animations';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field';

@Component({
  selector: 'bodyanalytics-manage-orders-table',
  templateUrl: './manage-orders-table.component.html',
  styleUrls: ['./manage-orders-table.component.scss'],
  standalone:true,
  animations,
  providers: [{ provide: MAT_FORM_FIELD_DEFAULT_OPTIONS, useValue: { appearance: 'outline' } }],

  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [MatTableModule, DatePipe, RouterLink, MatSortModule, JsonPipe,MaterialModule, NgIf],
})
export class ManageOrdersTableComponent extends BodyanalyticsBaseComponent implements AfterViewInit{

  protected clubDetail = RouterUrlsEnum.AUTH_ADMIN_MANAGE_ORDERS_DETAILS;

  protected displayedColumns = [ 'orderDate', 'firstName','city' , 'state' ];
  protected columnsToDisplayWithExpand = [...this.displayedColumns, 'expand'];
  protected expandedElement?: OrderI;

  entities = input.required<OrderI[]>();
 // Table data source
 dataSource = new MatTableDataSource<OrderI>();

 @ViewChild(MatPaginator) paginator!: MatPaginator;
 @ViewChild(MatSort) sort!: MatSort;

 constructor() {
   super();
   // React to changes in entities and update the table's data source
   effect(() => {
     const data = this.entities(); // Get the updated entities
     this.dataSource.data = data; // Set it to the MatTableDataSource
   });
 }

 ngAfterViewInit() {
   this.dataSource.paginator = this.paginator;
   this.dataSource.sort = this.sort;
   // Workaround to notify the table about data changes
   this.dataSource.paginator.page.subscribe(() => this.updateTable());
   this.dataSource.sort.sortChange.subscribe(() => this.updateTable());
 }

 applyFilter(event: Event) {
   const filterValue = (event.target as HTMLInputElement).value;

   this.dataSource.filter = filterValue.trim().toLowerCase();
   if (this.dataSource.paginator) {
     this.dataSource.paginator.firstPage();
   }
   this.updateTable();
 }
 // Explicitly trigger change detection for pagination, sort, and filter
 private updateTable() {
   this.dataSource._updateChangeSubscription();
 }
}


