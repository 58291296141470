import { Component, ChangeDetectionStrategy, input, signal } from '@angular/core';
import { BodyanalyticsBaseComponent, ContentEnum, RouterUrlsEnum } from '@bodyanalytics/ui-core';
import { ManageBodymeasurementsFormComponent } from '@bodyanalytics/front/admin/manage-bodymeasurements/ui';
import { GoogleAnalyticsService } from 'ngx-google-analytics';
import { Location } from '@angular/common';
import { toSignal, toObservable } from '@angular/core/rxjs-interop';
import { filter, switchMap } from 'rxjs';
import { ManageBodymeasurementService } from '@bodyanalytics/front/admin/manage-bodymeasurements/domain';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'bodyanalytics-manage-bodymeasurements-details',
  standalone: true,
  templateUrl: './manage-bodymeasurements-details.component.html',
  styleUrls: ['./manage-bodymeasurements-details.component.scss'],
  imports: [ManageBodymeasurementsFormComponent],
})
export class ManageBodymeasurementsDetailsComponent extends BodyanalyticsBaseComponent {
  protected contentEnum = ContentEnum;
  protected routerUrlsEnum = RouterUrlsEnum;

  id = input<string>();
  protected data = toSignal(
    toObservable(this.id).pipe(
      filter(Boolean),
      switchMap(id => this.manageBodymeasurementService.getBodyMeasurement(id)),
    ),
  );

  constructor(
    private $gaService: GoogleAnalyticsService,
    protected location: Location,
    private manageBodymeasurementService: ManageBodymeasurementService,
  ) {
    super();
    this.$gaService.pageView('manage-bodymeasurements/detail/' + this.id, 'Bodymeasurements Details');
  }
  public goBack() {
    this.location.back();
  }

  public processBodyMeasurementUpdate(details: any){
    //TODO: Add to update from  manageBodymeasurementService
  }
}
