import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';
import { differenceInYears } from 'date-fns';

export function minimumAgeValidator(minAge: number): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const dateOfBirth = control.value;
    if (!dateOfBirth) {
      return null; // return null if no date is provided
    }

    const age = differenceInYears(new Date(), new Date(dateOfBirth));
    return age >= minAge ? null : { minimumAge: { requiredAge: minAge, actualAge: age } };
  };
}
export function atLeastOneCheckboxValidator(): ValidatorFn {
  return (formGroup: AbstractControl): ValidationErrors | null => {
    const isRemindByEmail = formGroup.get('isRemindByEmail')?.value;
    const isRemindBySms = formGroup.get('isRemindBySms')?.value;

    if (isRemindByEmail || isRemindBySms) {
      return null; // valid if at least one is true
    } else {
      return { atLeastOneRequired: true }; // invalid if both are false
    };
  }
}
  export function validYearValidator(): (control: AbstractControl) => ValidationErrors | null {
    return (control: AbstractControl): ValidationErrors | null => {
      const value = control.value;

      if (!value) {
        return null; // required validator will handle empty case
      }

      const date = new Date(value);
      // If the date is invalid, return an error
      if (isNaN(date.getTime())) {
        return { invalidYear: true };
      }

      const year = date.getFullYear();
      const currentYear = new Date().getFullYear();

      // Adjust the conditions based on your valid year constraints.
      if (year < 1900 || year > currentYear) {
        return { invalidYear: true };
      }

      return null;
    };
  }

    export function noScriptTagValidator(control: AbstractControl): ValidationErrors | null {
      const value = control.value;
      if (!value) {
        return null; // If empty, let the 'required' validator handle it
      }

      // Check if the value contains a <script> tag.
      // This regex is a simple check. Adjust as needed.
      const scriptTagPattern = /<script\b[^<]*>(.*?)<\/script>/i;

      if (scriptTagPattern.test(value)) {
        return { disallowedContent: true };
      }
      return null;
    };
