<section id="manage-events-dashboard" class="dashboard bg-white">
  <div class="container">
    <div class="row text-white">
      <div class="col-md-8"></div>
      <div class="col-md-12 bg-white p-2 mx-auto">
        <div class="w-100 pb-2">

          <div class="card border-0">

            <div class="manage-events-container">
              <div class="row">
                <div class="border mx-auto">
                  <div class="card border-0">
                    <div class="card-body border-0">
                      <div class="row no-gutters">
                        <!-- Check if entities() has data -->
                        <ng-container *ngIf="entities()!.length > 0; else noDataTemplate">
                          <bodyanalytics-manage-events-table
                            [entities]="entities()"></bodyanalytics-manage-events-table>
                        </ng-container>

                        <!-- Template to display "No data" -->
                        <ng-template #noDataTemplate>
                          <bodyanalytics-default-empty-data-container></bodyanalytics-default-empty-data-container>
                        </ng-template>
                        <!-- <div class="list-group">

                              <button type="button" class="list-group-item list-group-item-action">
                                Signup List
                              </button>

                             <button type="button" class="list-group-item list-group-item-action" (click)="goToSignupPage()">
                               Email All
                              </button>

                              <button type="button" class="list-group-item list-group-item-action" (click)="goToSignupPage()">
                                View Club Details
                              </button>

                              <button type="button" class="list-group-item list-group-item-action" (click)="goToSignupPage()">
                                View Signup List
                              </button>

                            </div>
                           -->
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

          </div>

        </div>
      </div>
    </div>
  </div>
</section>
