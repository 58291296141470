<div class="container">
  @if (userName(); as userName) {
  <div class="row mt-2 mb-4">
    <div class="col-md-12 col-sm-12 bg-warning-subtle p-3">
      <h1>Welcome, {{ userName }}!</h1>
      <div class="lead">Find out what you're made of, and commit to your fitness goals!</div>
      <div class="row">
        <div class="col py-3">
          <button (click)="downloadLatestResult()" class="btn btn-primary" type="button">
            <i class='bx bxs-download'></i>Download Report
          </button>
        </div>
      </div>

    </div>
  </div>
  }@else {
    <div>
      <div class="row">
        <div class="col-md-6 mx-auto text-center">
          <div class="alert alert-info mt-3">
            <h4 class="alert-heading">Hello!</h4>
            <p>No data available. Book a new session to get started.</p>
            <a (click)="calendar()" class="btn btn-primary mt-2" type="button">Book a Session</a>
          </div>
        </div>
      </div>
    </div>

  }

  @if (bodyPercentagesChart!.length! >= 0) {
  <div *ngIf="reports()?.length! > 0" class="row mt-2 mb-4 ">
    <div class="col-md-8 col-sm-12">

      <h2 class="fw-bold">Test Results</h2>

      <h4 class="d-flex flex-wrap">
        Weight Composition Analysis
        <small style="font-weight: normal; margin-left: auto">
          Lb.
          <mat-slide-toggle [(ngModel)]="lbsKg" class="mat-mdc-slide-toggle mat-accent mat-mdc-slide-toggle-checked"
            hideIcon />
          Kg.
        </small>
      </h4>

      <div [options]="weightsChart()" echarts></div>

      <h4 class="pt-5">Body Composition Trends</h4>

      <div [options]="bodyPercentagesChart()" echarts></div>
    </div>
    <div class="col-md-4 col-sm-12 py-3 bg-light-subtle border">
      <h4 class="text-center">Your Body Composition</h4>

      <div [options]="bodyCompositionChart()" echarts></div>
    </div>
  </div>

  <div *ngIf="clientHistory().length! > 0" class="row mt-2 mb-4">
    <div class="col-md-12 col-sm-12">
      <h2 class="fw-bold">Test History</h2>
      <h4 class="lead">Your previous AppliedBioX tests</h4>

      @if (clientHistory(); as history) {
      <div class="table-responsive-sm table-responsive-md">
        <table class="table">
          <thead>
            <tr>
              <th scope="col">Date</th>
              <th scope="col">Height</th>
              <th scope="col">Weight</th>
              <th scope="col">Lean Lb.</th>
              <th scope="col">Fat Lb.</th>
              <th scope="col">Water Lb.</th>
              <th scope="col">Body Fat %</th>
              <th scope="col">Fat %<br />Change</th>
              <th scope="col">Weight<br />Change</th>
              <th scope="col">Lean Lb.<br />Change</th>
              <th scope="col">Fat Lb.<br />Change</th>
              <th scope="col">Fat %<br />Diff.</th>
            </tr>
          </thead>
          @for (item of history; track item.id) {
          <tbody>
            <tr>
              <td>{{ item.date }}</td>
              <td>{{ item.height }}</td>
              <td>{{ item.dryWeight }}</td>
              <td>{{ item.leanLbs }}</td>
              <td>{{ item.fatLbs }}</td>
              <td>{{ item.waterLbs }}</td>
              <td>{{ item.fatPercent }}</td>
              <td>{{ item.fatPercentDiff }}</td>
              <td>{{ item.weightPercentDiff }}</td>
              <td>{{ item.leanLbsDiff }}</td>
              <td>{{ item.fatLbsDiff }}</td>
              <td>{{ item.bodyFatPercentDiff }}</td>
            </tr>
          </tbody>
          }
        </table>
      </div>
      }
    </div>
  </div>
  }
