import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { EthnicityI, GenderI, SexI, SignupFormI, StateI, TestingEventI } from '@bodyanalytics/data-models-ui';
import { AuthService } from '@auth0/auth0-angular';
import {
  atLeastOneCheckboxValidator,
  ethnicityData,
  genderData,
  minimumAgeValidator,
  mobileTestingServices,
  noScriptTagValidator,
  raceData,
  sexData,
  validYearValidator,
} from '@bodyanalytics/ui-core';
import { RacesI } from '../../../../../data-models-ui/src/lib/profile';
import { SignupService } from '../../services/signup.service';

@Component({
  selector: 'bodyanalytics-signup-form',
  templateUrl: './signup-form.component.html',
  styleUrls: ['./signup-form.component.scss'],
})
export class SignupFormComponent {
  @Output() submitFormEvent = new EventEmitter<any>();
  @Input()
  item!: TestingEventI;

  @Input() initialData!: SignupFormI;
  protected stateList: StateI[] = [];
  readonly ethnicityList: EthnicityI[] = [];
  readonly raceList: RacesI[] = [];
  readonly genderList: GenderI[] = [];
  readonly sexList: SexI[] = [];
  isEditEnabled: boolean = true;
  formsubmit: boolean = false;
  mobileTestingService = [...mobileTestingServices];
  public readonly phonePatternValidator = '/(d{3})-d{3}-d{4}/';
  public phoneMask = {
    guide: true,
    showMask: false,
    mask: [/\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/],
  };
  public signupForm = new FormGroup(
    {
      firstName: new FormControl('', [Validators.required,Validators.maxLength(20),Validators.pattern('^[a-zA-Z0-9]+(-[a-zA-Z0-9]+)*$')]),
      lastName: new FormControl('', [Validators.required,Validators.maxLength(24), Validators.pattern("^[a-zA-Z0-9]+([-' ][a-zA-Z0-9]+)*$")]),
      emailAddress: new FormControl('', [Validators.required, Validators.email]),
      phoneNumber: new FormControl('', [Validators.required,Validators.maxLength(14)]),
      address1: new FormControl('', [Validators.required,Validators.maxLength(60), noScriptTagValidator]),
      city: new FormControl('', [Validators.required,Validators.maxLength(20)]),
      state: new FormControl('', [Validators.required]),
      zipCode: new FormControl('', [Validators.required, Validators.maxLength(5)]),
      dob: new FormControl('', [Validators.required, minimumAgeValidator(15),validYearValidator()],),
      sex: new FormControl('', [Validators.required]),
      race: new FormControl('', [Validators.required]),

      isRemindByEmail: new FormControl(false),
      isRemindBySms: new FormControl(false),
    },
    {
      validators: atLeastOneCheckboxValidator(),
    },
  );

  profileJson = '';

  constructor(
    public auth: AuthService,
    private signupService: SignupService,
  ) {
    this.genderList = genderData;
    this.sexList = sexData;
    this.ethnicityList = ethnicityData;
    this.raceList = raceData;
  }

  ngOnInit(): void {
    this.auth.user$.subscribe(profile => (this.profileJson = JSON.stringify(profile, null, 2)));

    this.signupService.getStates().subscribe(state => {
      this.stateList = state;
    });

    if (this.initialData) {
      this.signupForm.patchValue({
        firstName: this.initialData.firstName as string,
        lastName: this.initialData.lastName as string,
        emailAddress: this.initialData.emailAddress as string,
        phoneNumber: this.initialData.phoneNumber as string,
        address1: this.initialData.address1 as string,
        dob: this.initialData.dob as string,
        city: this.initialData.city,
        zipCode: this.initialData.zipCode,
        state: this.initialData.state,
        race: this.initialData.race as string,
        sex: this.initialData.sex as string,
        isRemindBySms: this.initialData.isRemindBySms,
        isRemindByEmail: this.initialData.isRemindByEmail,
      });
    }
  }

  public updateSignupDetails(): void {
    if (this.signupForm.valid) {
      this.submitFormEvent.emit({
        firstName: this.signupForm.value.firstName?.trim() as string,
        lastName: this.signupForm.value.lastName?.trim() as string,
        emailAddress: this.signupForm.value.emailAddress?.trim() as string,
        phoneNumber: this.signupForm.value.phoneNumber?.trim() as string,
        dob: this.signupForm.value.dob as string,
        address1: this.signupForm.value.address1?.trim(),
        city: this.signupForm.value.city?.trim(),
        zipCode: this.signupForm.value.zipCode?.trim(),
        state: this.signupForm.value.state,
        race: this.signupForm.value.race as string,
        sex: this.signupForm.value.sex as string,
        isRemindBySms: this.signupForm.value.isRemindBySms,
        isRemindByEmail: this.signupForm.value.isRemindByEmail,
      } as SignupFormI);
    } else {
      // alert('Please fill in the form correctly.');
    }
  }

  /**
   * Returns form
   */
  get form() {
    return this.signupForm.controls;
  }

  /**
   * Bootstrap tooltip form validation submit method
   */
  formSubmit() {
    this.formsubmit = true;
  }
}
