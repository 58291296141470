export const environment = {
  production: false,
  api: 'https://dev.backendapi.appliedbiox.com',
  auth0: {
    domain: 'https://dev-k81cxnrfzle2ohvb.us.auth0.com',
    clientId: 'Jb1QObPhLAln9EgI72gnHc957giHu9Xs',
    authorizationParams: {
      audience: 'http://abx-api-admin.com',
      redirect_uri: 'https://dev.admin.appliedbiox.com',
    },
    errorPath: '/error',
  },
  calendarUrl: '',
  stripePKey: '',
  portalName: '',
  stripePlanUrl: '',
  companyId:'',
  analyticsMeasurementId: '',
  stripe_package_price_1: '',
  stripe_package_price_2: '',
  stripe_package_price_3: '',
  stripe_package_price_4: '',
  stripe_package_price_5: '',
  stripe_package_price_6: '',
  stripe_package_price_7: '',
};
