<section id="manage-discounts-dashboard" class="dashboard bg-white">
  <div class="container">
    <div class="row text-white">
      <div class="col-md-12 bg-white p-2 mx-auto">
        <div class="w-100 pb-2">


          <div class="card-body border-0">
            <div class="manage-discounts-container">
              <div class="row">
                <div class="border mx-auto">
                  <div class="card border-0">
                    <div class="card-body border-0">
                      <div class="row no-gutters">
                        <ng-container *ngIf="entities()!.length > 0; else noDataTemplate">
                          <bodyanalytics-manage-discounts-table [entities]="entities()" />
                        </ng-container>
                        <ng-template #noDataTemplate>
                          <bodyanalytics-default-empty-data-container></bodyanalytics-default-empty-data-container>
                        </ng-template>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>
    </div>
  </div>
</section>
